/* Gold      F2CC8C      */
/* Archteicture tones 
Sky blue : EAF5FA  
Sky blue2 : 9CBBD0   
Medium blue: 49617D   
Dark Gray : 2E313A   
White Gray : B7B9C6 
Super white Gray :  DFDCE7 */

/* NEW CSS BELOW */

.landingContainer {
  overflow: hidden;
}

.paddingContainer {
  padding-left: 1%;
  padding-right: 1%;
}

.forceHeight {
  height: 100vh !important;
}

.heightBypass {
  padding-top: 5vw !important;
}

.emptySpace {
  height: 80px !important;
}

.emptySpaceFloat {
  height: 20px !important;
  width: 100%;
  clear: both;
}

.emptySpace2 {
  width: 100px !important;
  color: rgba(0, 0, 0, 0) !important;
}

.divider {
  height: 20px !important;
}

.textdivider {
  display: flex !important;
  text-align: justify center !important;
  justify-content: center !important;
  align-items: center !important;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: rgba(255, 255, 255, 0.151);
  border-radius: 50%;
  display: inline-block;
}

.titleContainer {
  font-weight: normal;
  text-align: justify center !important;
  font-size: 48px !important;
  text-align: left;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.lefttitleContainer {
  font-family: "Archivo Black", sans-serif;
  position: absolute !important;
  font-weight: normal;
  width: 100% !important;
  height: 15% !important;
  text-align: justify center !important;
  font-size: 24px !important;
}

.leftContainer {
  padding-top: 180px;
  padding-left: 30px;
  text-align: center;
}

.middleContainer {
  padding-top: 180px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.rightContainer {
  padding-top: 180px;
  padding-right: 30px;
  text-align: center;
}

.bodyPink {
  background-color: rgb(226, 81, 255) !important;
}


.mixDifference {
  mix-blend-mode: difference !important;
}

.repeatingDotPattern {
  background: url("./Images/background/DotPattern5.png") repeat center;
  position: fixed;
  width: 200vw;
  height: 200vh;
  background-attachment: fixed;
  /*animation: movingDots 10s linear infinite;*/
  z-index: -1;
}

.splashBackground {
  position: fixed;
  width: 200vw;
  height: 200vh;
  background-attachment: fixed;
  transition: all 0.5s ease;
  z-index: 0;
}

.fadeInAnimation{
  animation: fadeIn 1s linear forwards;
  opacity: 0 !important;
  animation-delay: 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0 !important;
  }
  100% {
    opacity: 1 !important;
  }
}

@keyframes movingDots {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 512px;
  }
}

.gradientBackground {
  position: fixed;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  -webkit-transition: ease 2s; /* For Safari 3.0 to 6.0 */
  transition: ease 2s; /* For modern browsers */
  animation: gradientFade 3s forwards !important;
  z-index: -1;
}

@keyframes gradientFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.gradientBlue {
  background: linear-gradient(to bottom, rgba(0, 166, 255, 0), #007abb);
}

.gradientSoftYellow {
  background: linear-gradient(to bottom, rgba(185, 221, 240, 0.2), #d0b66a);
}

.gradientBlack {
  background: linear-gradient(to bottom, rgba(0, 166, 255, 0), #007abb);
}

.bannerMoveTop {
  animation: banner-move2 1.25s forwards !important;
  animation-delay: 2s;
  display: inline-block;
}

.bannerMoveTop2 {
  animation: banner-move2 1.25s forwards !important;
  animation-delay: 2.3s;
  display: inline-block;
}

@keyframes banner-move2 {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}

.bannerWhite {
  color: rgba(255, 255, 255, 1);
  animation: color-change 3s forwards !important;
  animation-delay: 0.5s;
}

@keyframes color-change {
  0% {
    color: rgba(0, 122, 187, 0);
    text-shadow: 0px 0px 0px #003e42;
  }
  100% {
    color: rgba(255, 255, 255, 1);
    text-shadow: 4px 4px 0px #003e42;
  }
}

.gradientOrange {
  background: linear-gradient(to bottom, rgba(0, 166, 255, 0), #aee0ff);
}

.gradientGreen {
  background: linear-gradient(
    to bottom,
    rgba(0, 166, 255, 0),
    rgb(66, 255, 138)
  );
}

.gradientBlue {
  background: linear-gradient(to bottom, rgba(0, 166, 255, 0), #045e96);
}

.gradientCozy {
  background: url("./Images/work/cozycafe/cozycafebigsplash.png") no-repeat
    center center;
  background-size: auto 100%;
  transition: ease 1s;
  background-attachment: fixed !important;
  transition: opacity 1.5s ease-in-out;
  -webkit-transition: opacity 1.5s ease-in-out;
  -moz-transition: opacity 1.5s ease-in-out;
  opacity: 0;
}

.gradientBattleRun {
  background: url("./Images/work/battlerun/BattleRunSplash.png") no-repeat
    center center;
  background-size: auto 100%;
  transition: ease 1s;
  background-attachment: fixed !important;
  transition: opacity 1.5s ease-in-out;
  -webkit-transition: opacity 1.5s ease-in-out;
  -moz-transition: opacity 1.5s ease-in-out;
  opacity: 0;
}

.gradientBoss {
  background: url("./Images/work/boss4/boss4.png") no-repeat center center;
  background-size: auto 100%;
  transition: ease 1s;
  background-attachment: fixed !important;
  transition: opacity 1.5s ease-in-out;
  -webkit-transition: opacity 1.5s ease-in-out;
  -moz-transition: opacity 1.5s ease-in-out;
  opacity: 0;
}

.gradientBlue {
  background: linear-gradient(to bottom, rgba(77, 182, 172, 0), #007abb);
}

/* Text Section */

.section {
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  border: rgba(156, 187, 208, 0.8) 0px solid;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  padding-right: 10px;
  padding-left: 10px;
  margin: 10px;
  font-size: 12px;
  line-height: 1.1;
}

.blueSection {
  display: block;
  background-color: #0079bb89;
  border: rgba(156, 187, 208, 0.8) 1px solid;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
}

.bigText {
  font-size: 24px !important;
  font-weight: bolder;
  font-family: "Poppins", sans-serif;
}

.leftText {
  text-align: left !important;
}

.rightText {
  text-align: right !important;
}

.whiteText {
  color: #fff;
}

.smallText {
  font-size: 12px;
}

.boldText {
  font-weight: bold !important;
}

.greyText {
  color: #424242;
  font-size: 12px;
}

.secondaryText {
  color: #003e42;
}

.subtitleText {
  font-size: 20px !important;
  font-weight: bolder;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  line-height: normal;
  height: 100%;
  width: 100%;
  color: #003e42;
}

.largerText {
  font-size: 20px !important;
  font-weight: bolder;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  line-height: normal;
  height: 100%;
  width: 100%;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

/* Landing */

.bigAvatar {
  width: auto;
  height: 90%;
  padding: 4px;
}

.bigCircle {
  background: #003e42;
  border: 0px solid #003e42;
  border-radius: 30px;
  width: fit-content;
  color: #003e42;
  border-radius: 50%;
  width: 85%;
  height: 85%;
  z-index: 0;
  position: relative;
}

.fixedimg {
  width: 64px;
  height: 64px;
  padding: 5px;
}

.smallfixedimg {
  width: 32px;
  height: 32px;
  padding: 5px;
}

.shiftleft {
  position: relative;
  left: -15px !important;
}

.shiftright {
  position: relative;
  left: 20px !important;
}

.shiftleftmore {
  position: relative;
  left: -15px !important;
}

.shiftdown {
  padding-top: 3px !important;
}

.leftfloat {
  width: 10%;
  float: left;
}

.rightfloat {
  width: 88%;
  float: right;
}

.circle {
  background: rgba(255, 255, 255, 1);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  z-index: 100;
  position: relative;
}

.centerElement {
  align-content: center !important;
  text-align: center !important;
  vertical-align: center !important;
}

.centerImage {
  position: relative;
  margin: auto !important;
  display: block;
  width: 90%;
}

.landingTextContainer {
  align-content: center;
  text-align: left;
  vertical-align: center;
  display: inline-block;
}

.subBanner {
  background: rgba(255, 255, 255, 0.35);
  z-index: 1;
  position: relative;
  border-radius: 15px;
  padding: 5px;
  padding-left: 30px;
  margin-left: -30px;
}

.bannerMoveBottom {
  animation: banner-move2 1s forwards !important;
  animation-delay: 1.5s;
  display: inline-block;
}

@keyframes banner-move {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}

.smallText {
  font-size: 14px !important;
}

.mediumText {
  color: rgb(250, 250, 250);
  font-size: 16px !important;
  font-weight: 300;
  line-height: 1.5;
}

.mediumText span {
  text-decoration: none !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 1000 !important;
  display: inline !important;
}

.icon {
  display: inline-block;
  padding: 0;
  vertical-align: baseline;
  height: 16px;
  width: 16px;
  filter: invert(19%) sepia(14%) saturate(3681%) hue-rotate(141deg)
    brightness(93%) contrast(101%);
}

.iconColor {
  filter: invert(19%) sepia(14%) saturate(3681%) hue-rotate(141deg)
    brightness(93%) contrast(101%);
}

.text-link {
  color: inherit;
  text-decoration: none !important;
}

.fancyButton {
  background-color: #fff;
  border: 2px solid #003e42;
  border-radius: 30px;
  box-shadow: #003e42 4px 4px 0 0;
  width: fit-content;
  color: #003e42;
  cursor: pointer;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  line-height: 24px;
  -webkit-transition: ease 1s; /* For Safari 3.0 to 6.0 */
  transition: ease 1s; /* For modern browsers */
}

.fancyButtonMobile {
  width: 80% !important;
}

.fancyButton:hover {
  background-color: #86f0f7;
  border: #86f0f7 2px solid !important;
  box-shadow: #0eb6c2 4px 4px 0 0 !important;
}

.fancyButton:active {
  box-shadow: #003e42 2px 2px 0 0;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .fancyButton {
    min-width: 120px;
    padding: 0 25px;
  }
}

.fadeInOpacity {
  opacity: 0;
  animation: fadeText 1.5s forwards !important;
  animation-delay: 1.5s !important;
}

@keyframes fadeText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.landingDivider {
  border: 0;
  height: 15px;
  top: 10px;
  position: relative;
  opacity: 1;
  background-image: linear-gradient(to right, #003e42, rgba(204, 204, 204, 0));
}

/* Card */

.MuiPaper-root {
  width: 100%;
  background-color: #fff !important;
  border: #003e42 2px solid !important;
  background-color: #fff;
  border-radius: 30px !important;
  box-shadow: #003e42 4px 4px 0 0 !important;
  color: #003e42;
  cursor: pointer !important;
  display: inline-block !important;
  line-height: 50px;
  text-align: center;
  text-decoration: none !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  touch-action: manipulation;
  -webkit-transition: ease 1s; /* For Safari 3.0 to 6.0 */
  transition: ease 1s; /* For modern browsers */
  filter: brightness(100%);
  overflow-x: none !important;
}

.MuiPaper-root:hover {
  border: #86f0f7 2px solid !important;
  box-shadow: #0eb6c2 4px 4px 0 0 !important;
  filter: brightness(90%);
}

.MuiCardContent-root {
  padding: 8px !important;
}

.cardHeader {
  color: #003e42;
  font-size: 24px !important;
  font-family: "Poppins", serif !important;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bolder !important;
}

label {
  padding: 2px;
  background-color: #003e42be;
  border-color: #eaf5fa;
  border-radius: 5px;
  font: bold;
  color: #fff;
}

label2 {
  padding: 4px;
  background-color: #003e42be;
  border-color: #eaf5fa;
  border-radius: 5px;
  padding: 2px;
  font: normal;
  color: #fff;
  font-weight: bold;
}

labelWork {
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: rgba(0, 0, 0, 0.25);
  border-color: #eaf5fa;
  border-radius: 5px;
  font: bolder !important;
  font-size: 18px;
  color: #eaf5fa;
  line-height: 1.6;
}

labelBigText {
  font: bolder !important;
  font-size: 30px;
  color: #eaf5fa;
}

.underline {
  text-decoration: underline solid;
}

.textPadding {
  padding-left: 10px !important;
  padding-right: 10x !important;
}

.cardMinHeight {
  min-height: 80px;
}

.noMargin {
  margin: 0 !important;
}

/* Work Section */

.workSection {
  display: block;
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1vw;
  line-height: 1.1;

  border-radius: 25px 25px 25px 25px; /* This line was updated */
}

.workSectionMobile {
  display: block;
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1vw;
  line-height: 1.1;

  border-radius: 0px 25px 25px 25px; /* This line was updated */
}

.imageContainer {
  width: 100%;
  height: 100%;
}

.imageHeader {
  height: 100px;
  width: auto;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  text-align: center;
}

.imageFatHeader {
  height: auto;
  width: 90%;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  text-align: center;
}

.imageHeaderMobile {
  height: auto;
  width: 80%;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  text-align: center;
}

.cuteHeader {
  height: 100px;
  width: auto;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.cuteHeader2 {
  height: 90px;
  width: auto;
  display: inline-block;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
}

.cuteHeaderMobile {
  width: 90%;
  height: auto;
  display: inline-block;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
}

.cuteHeaderMobile2 {
  width: 120%;
  height: auto;
  display: inline-block;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
}

.imageHeader2 {
  height: 125px;
  width: auto;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.smallPadding {
  padding: 10px;
}

.imageFit {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.cutehangingPosition {
  position: relative;
  top: 33px;
  left: 0%;
}

.cutehangingPosition2 {
  position: relative;
  vertical-align: bottom;
  height: auto;
  top: 4vw;
  width: 80% !important;
}

.cuteMobileContainer {
  display: flex;
  align-items: flex-end;
}

.cutehangingPositionAbsolute {
  position: absolute;
  top: 43px;
  left: 70%;
  overflow: hidden;
}

.cutehangingLeft {
  position: relative;
  top: 10%;
  overflow: hidden;
}

.cutehangingRight {
  position: relative;
  top: 8%;
  left: -5%;
}


.runhangingPosition {
  position: relative;
  top: -10%;
  left: 0%;
}

.runhangingPositionMacie {
  position: relative;
  top: 0%;
  left: -50px;
  overflow: hidden;
}

.runhangingPositionMobile {
  position: relative;
  top: -10%;
  left: -20%;
}

.workHeaderSection {
  display: block;

  background-image: linear-gradient(
    to bottom,
    rgba(169, 85, 32, 0.4),
    rgba(0, 0, 0, 0.25)
  );
  color: #fff;

  font-size: 1vw;
  line-height: 1.1;
  border-radius: 25px 25px 0 0; /* This line was updated */
}

.workHeaderSection2 {
  display: block;

  background-image: linear-gradient(
    to bottom,
    rgba(59, 255, 180, 0.3),
    rgba(0, 0, 0, 0.25)
  );
  color: #fff;

  font-size: 1vw;
  line-height: 1.1;
  border-radius: 25px 25px 0 0; /* This line was updated */
}

.workSubtitle {
  font-size: 20px !important;
  font-weight: bolder !important;
}

/*  Project + Work Pages */

.sectionContent {
  width: 95%;
  padding-left: 12px;
  padding-right: 12px;
}

.sectionName {
  width: 55%;
  padding-left: 5%;
  padding-right: 5%;
  margin: auto;
}

.sectionNameMobile {
  width: 80%;
  padding-left: 2%;
  padding-right: 2%;
  margin: auto;
}

@media (min-width: 960px) {
  .sectionName {
    width: 95% !important;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.sectionContentLeft {
  width: auto;
  float: left; /* [1] */
  padding-right: 5px;
}

.sectionContentMiddle {
  overflow: auto; /* mix-blend-mode: difference !important; */
  padding-top: 2px;
}

.floatFill {
  float: none;
  overflow: hidden;
  padding-top: 2px;
}

.sectionPadding {
  padding-top: 10px !important;
}

.sectionContentRight {
  width: auto;
  float: right; /* [3] */
  padding-left: 5px;
  text-align: right !important;
}

/* OLD CSS BELOW */

body {
  overflow-y: scroll !important;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin: 0;
  height: 100%;
  background-color: rgb(0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000 no-repeat center center fixed;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-size: 1em;
  -webkit-transition: ease 2s; /* For Safari 3.0 to 6.0 */
  transition: ease 2s; /* For modern browsers */
}

ul.header li {
  z-index: 2;
  color: #fff;
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
}
ul.header {
  z-index: 2;
  display: block;

  list-style-type: none;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  -webkit-transition: ease 1s; /* For Safari 3.0 to 6.0 */
  transition: ease 1s; /* For modern browsers */
}

.visiblenav {
  background-color: #003e42;
  padding-bottom: 5px !important;
}

.orangeNav {
  background-color: #9f5014;
  padding-bottom: 5px !important;
}

.pinkNav {
  background-color: #ed81a1;
  padding-bottom: 5px !important;
}

.greenNav {
  background-color: rgb(13, 138, 61);
  padding-bottom: 5px !important;
}

.redNav {
  background-color: #af4154;
  padding-bottom: 5px !important;
}

.headerPadding {
  height: 80px !important;
}

.centerText {
  text-align: center !important;
}

.leftText {
  text-align: left !important;
}

.hidden {
  display: none !important;
}

.top2 {
  top: 60px !important;
}

.top3 {
  top: 120px !important;
}

.top4 {
  top: 180px !important;
}

.top5 {
  top: 240px !important;
}

.top6 {
  top: 300px !important;
}

.top7 {
  top: 360px !important;
}

ul {
}

.headerNav {
  -webkit-transition: ease 1s; /* For Safari 3.0 to 6.0 */
  transition: ease 1s; /* For modern browsers */
}

ul.header li a {
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.09em;
  display: inline-block;
  margin-top: 10px;
  font: bold;
  font-weight: bold;
  padding: 8px;
  padding-right: 8px;
  padding-left: 8px;
  position: relative;
  -webkit-transition: ease 1s; /* For Safari 3.0 to 6.0 */
  transition: ease 1s; /* For modern browsers */
  margin-left: 10px;
  margin-right: 10px;
  width: 115px;
}

ul.header li a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.5s ease 0s, left 0.3s ease 0s;
  width: 0;
}

ul.header li a:hover::after {
  width: 100%;
  left: 0;
}
ul.header li a:focus,
a:active {
  color: #49617d;
}

ul.header li a:active::before {
  background-color: #00bcd4;
}

ul.header li a:hover::before,
ul.header li a:focus::before {
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

ul.header li a:focus {
  border-color: rgb(205, 205, 205);
}

.menubutton {
  transition-timing-function: ease;
  transition: 0.2s;
  width: 70vw !important;
}

.closemenu {
  background: rgba(255, 105, 97, 0.7) !important;
}

.menubutton:active::after {
  color: #49617d !important;
  background: #49617d !important;
}

.active {
  background-color: rgba(255, 255, 255, 0.8);
  border-color: rgb(205, 205, 205);
  color: #000 !important;
}

.menubuttonWork{
  background-color: rgba(195, 195, 195, 1) !important;
  transition: 0.2s;
  width: 50vw !important;
}

li {
  display: block;
}

/*  Testing Card */

.card {
  padding-top: 50%;
}

.card-highlight {
  -webkit-transition: ease 1s; /* For Safari 3.0 to 6.0 */
  transition: ease 1s; /* For modern browsers */
}
.card-highlight:hover {
  background-color: #86f0f7;
  /*color: rgba(255, 255, 255, 1) !important;*/
}

.height100 {
  height: 100% !important;
}

/* New Landing CSS */

.landingCard {
  aspect-ratio: 1 / 1;
}

.shortCard {
  height: 10vh;
}

.orangeCard {
  background: orange;
}

/* Test Work Experience 3 Bar */

.tallCard {
  height: 60vh;
  width: inherit;
}

/*special case for video-react to prevent glitches */
@media (max-width: 960px) {
  .video-react-fullscreen-control {
    display:none !important;
  }
}


/* Hovering Overlay TEST 
.container-overlay {
  position: relative;
  text-align: center;
  color: white;
}

@media (min-width: 600px) {
  .hvr-sweep-to-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-sweep-to-top:hover:before,
  .hvr-sweep-to-top:focus:before,
  .hvr-sweep-to-top:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  .hvr-sweep-to-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-sweep-to-top:hover:before,
  .hvr-sweep-to-top:focus:before,
  .hvr-sweep-to-top:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  .hvr-sweep-to-right {
    padding: 1%;
  }

  .hvr-sweep-to-right:hover + .container-overlay {
    opacity: 0;
  }

  .hvr-sweep-to-right:before {
    content: "";
    position: fixed;
    z-index: -3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.8s;
    transition-duration: 0.8s;
    opacity: 0;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    transition: 0.5s ease;
    transition-delay: 0.1s;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .autobattler.hvr-sweep-to-right:before {
    background: linear-gradient(to top, #cbe3ba00, #ffffff),
      url("./Images/card/card-autobattler.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transition: 1s ease-in-out;
  }

  .workexperience.hvr-sweep-to-right:before {
    background: url("./Images/card/card-titan.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transition: 1s ease-in-out;
  }

  .sandbox.hvr-sweep-to-right:before {
    background: url("./Images/card/card-sandbox.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transition: 1s ease-in-out;
  }

  .react.hvr-sweep-to-right:before {
    background: linear-gradient(to top, #cbe3ba00, rgb(35, 48, 62)),
      url("./Images/card/react-logo.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transition: 1s ease-in-out;
  }
  .hvr-sweep-to-right:hover:before,
  .hvr-sweep-to-right:focus:before,
  .hvr-sweep-to-right:active:before {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    opacity: 0.7;
    transition: 1s ease-in-out;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
*/
